<template>
  <div>
<!--    <div style="margin-bottom: 15px">-->
<!--      <el-button-->
<!--          class="mr10"-->
<!--          type="primary"-->
<!--          @click="addTem"-->
<!--      >新增模板</el-button>-->
<!--    </div>-->
    <div class="wocao">
      <div class="template_item" v-for="(item,index) in tableData" :key="index">
        <img class="img" v-if="item.isUse == 0" src="../../assets/images/use.png" alt="">
        <div class="top_wrap">
          <div class="template_title">{{item.templateTitle}}</div>
          <div class="template_use" v-if="item.isUse != 0" @click="employ(item)">使用模板</div>
        </div>
        <div class="img_wrap" @mouseenter="onMouseOver(index)"  @mouseleave="onMouseOut(index)">
          <img :src="item.displayImg" alt="" class="template_bg">
          <!--        <div class="template_bg" v-if="!ifShow" :style="{ backgroundImage: `url(${item.displayImg})` }"></div>-->
          <div class="view_img" v-if="item.ifShow">
            <div @click="view(item)">预览</div>
          </div>
        </div>
        <div class="hangye_wrap">
          <span>适用于:</span>
          <span class="item" v-for="(item1,index1) in item.describes" :key="index1">{{item1}}</span>
        </div>
      </div>
    </div>
    <el-dialog title="模板" :visible.sync="centerDialogVisible" width="50%" :modal-append-to-body="false" center>
      <div>
        <img :src="ylUrl" alt="" style="width: 100%">
      </div>
    </el-dialog>
    <el-dialog
        title="添加模板"
        :visible.sync="centerDialogVisible_nav"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
      >
        <el-form-item label="模板名称" prop="title">
          <el-input clearable v-model="ruleForm.title"  maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="模板简述" prop="desc">
          <el-input clearable type="textarea" v-model="ruleForm.desc" placeholder="模板简述" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="模板展示图" prop="temImg">
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="模板预览图" prop="temViewImg">
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled1 }"
                     :on-remove="handleRemove1"
                     :on-success="handleSuccess1"
                     :file-list="fileList1"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="预览URL">
          <el-input clearable v-model="ruleForm.link" placeholder="请预览URL" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
	import {
		selectTemplateSettingsPageList,
		addTemplateSettings,
		upTemplateSettings
	} from '@/api/website'
	export default {
		name: "templateLibrary",
		data() {
			return {
				tableData: [], // 模板列表
				num: 1, // 默认
				ylUrl: '', //
				centerDialogVisible: false,
        centerDialogVisible_nav:false,
				activeName: "first",
        hylist:[],
        ifShow:false,
        ruleForm: {
          title: "",
          desc: "",
          link:'',
          temImg:'',
          temViewImg:'',
        },
        rules: {
          title: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
          desc: [{ required: true, message: "请输入简述", trigger: "blur" }],
          temImg: [{ required: true, message: "请上传展示图" }],
          temViewImg: [{ required: true, message: "请上传预览图" }],
        },
        uploadUrl:this.$store.state.uploadingUrl,
        ossUrl:this.$store.state.ossUrl,
        dialogImageUrl: "",
        dialogVisible: false,
        fileList:[],
        fileList1:[],
			};
		},
    computed: {
      uploadDisabled(){
        return this.fileList.length !== 0;
      },
      uploadDisabled1(){
        return this.fileList1.length !== 0;
      },
    },
		mounted() {
			this.queryPage();
		},
		methods: {
      addTem(){
        this.centerDialogVisible_nav = true
      },
      close(){
        (this.ruleForm = {
          title: "",
          desc: "",
          link:"",
          temImg:"",
          temViewImg:""
        })
        this.fileList = []
        this.fileList1 = []
        this.centerDialogVisible_nav = false;
      },
      determine(){
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            try {
              let data = {
                associationId:localStorage.getItem("associationId"),
                templateTitle:this.ruleForm.title,
                describes:this.ruleForm.desc,
                displayImg:this.ruleForm.temImg,
                preview:this.ruleForm.temViewImg,
                jumpUrl:this.ruleForm.link
              };
              this.$message({
                message: "正在保存",
              });
              await addTemplateSettings(data);
              this.centerDialogVisible_nav = false;
              this.$message.closeAll();
              this.$message({
                message: "保存成功",
                type: "success",
              });
              await this.queryPage();
            } catch (error) {
              this.$message.closeAll();
              this.$message({
                message: error.message,
                type: "error",
              });
              this.centerDialogVisible_nav = false;
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
			// 模板列表
			async queryPage() {
				let data = {
          associationId:localStorage.getItem("associationId"),
        };
				try {
					const result = await selectTemplateSettingsPageList(data);
					const {
						total,
						list
					} = result.data.pageInfo;
					list.forEach(item=>{
						item['ifShow'] = false
            item.describes = item.describes.split(',')
					})
					this.tableData = list;
				} catch (error) {
					this.loading = false;
				}
			},
			handleClick(tab) {
				const {
					name
				} = tab;
				this.activeName = name;
			},
			// 预览模板
			view(item) {
        window.open(item.jumpToUrl,'_blank')
			},
			// 使用模板
			async employ(item) {
				let data = {
					associationId: localStorage.getItem("associationId"),
					templateSettingsId: item.templateSettingsId,
					isUse: 0, // 使用
				}
				try {
					this.$message({
					  message: "正在保存",
					});
					const result = await upTemplateSettings(data);
					this.$message({
					  message: "保存成功",
					  type: "success",
					});
					await this.queryPage();
				} catch (error) {
					this.$message.closeAll();
					this.$message({
					  message: error.message,
					  type: "error",
					});
				}
			},
      onMouseOver(index){
        this.tableData[index].ifShow = true
      },
      onMouseOut(index){
        this.tableData[index].ifShow = false
      },
      handleRemove(file, fileList) {
        this.fileList = []
        this.ruleForm.temImg = ''
      },
      handleSuccess(response, file, fileList) {
        this.$message.closeAll();
        this.$message({
          message: "上传成功",
          type: "success",
        });
        console.log('file',file)
        this.ruleForm.temImg = this.ossUrl +  file.response.data
        this.fileList = fileList;
      },
      handleRemove1(file, fileList) {
        this.fileList1 = []
        this.ruleForm.temViewImg = ''
      },
      handleSuccess1(response, file, fileList) {
        this.$message.closeAll();
        this.$message({
          message: "上传成功",
          type: "success",
        });
        console.log('file',file)
        this.ruleForm.temViewImg = this.ossUrl +  file.response.data
        this.fileList1 = fileList;
      },
		},
	};
</script>

<style lang="scss" scoped>
	.wocao {
		display: flex;
		flex-wrap: wrap;
	}

	.orderManagement-view {
		display: flex;
		flex-wrap: wrap;
	}

	.template_item {
		display: flex;
		flex-direction: column;
		margin-right: 20px;
		margin-bottom: 20px;
		justify-content: center;
		align-self: center;
		width: 313px;
    background-color: #FFFFFF;
    padding: 20px;
    position: relative;
    &:nth-child(5n){
      margin-right: 0;
    }
	}
  .template_item>img{
    width: 68px;
    height: 54px;
    position: absolute;
    right: 16px;
    top: -4px;
  }
  .top_wrap{
    display: flex;
    justify-content: space-between;
  }

  .template_use{
    cursor: pointer;
    width: 72px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #51CBCD;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #51CBCD;
  }

  .img_wrap{
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 220px;
    background: #F5F7F9;
    padding: 16px 36px;
  }

	.template_bg {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
  .view_img{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(51, 51, 51, 0.7);
    z-index: 99;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

	.view_img>div {
		background: #51CBCD;
		width: 80px;
		height: 36px;
		line-height: 36px;
		margin: 0 auto;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
		color: #FFFFFF;
	}

	.template_title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B3036;
    margin-bottom: 25px;
	}
  .hangye_wrap{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    max-height: 52px;
    overflow: hidden;
    .item{
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin-left: 6px;
      margin-bottom: 6px;
    }
  }
  .hangye_wrap>span:nth-child(1){
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #86909C;
  }
  .hangye_wrap>span:nth-child(2){
    margin-left: 14px;
    background: rgba(81, 203, 205, .1);
    color: #51CBCD;
  }
  .hangye_wrap>span:nth-child(3){
    background: rgba(84, 189, 113, .1);
    color: #54BD71;
  }
  .hangye_wrap>span:nth-child(4){
    background: rgba(202, 162, 85, .1);
    color: #CAA255;
  }
  .hangye_wrap>span:nth-child(5){
    background: rgba(100, 137, 198, .1);
    color: #6489C6;
  }
  .hangye_wrap>span:nth-child(6){
    background: rgba(144, 123, 198, .1);
    color: #907BC6;
  }
  .hangye_wrap>span:nth-child(7){
    background: rgba(233, 135, 81, .1);
    color: #E98751;
  }
  .hangye_wrap>span:nth-child(8){
    background: rgba(59, 188, 147, .1);
    color: #3BBC93;
  }
  .hangye_wrap>span:nth-child(9){
    background: rgba(149, 164, 79, .1);
    color: #95A44F;
  }
  .hangye_wrap>span:nth-child(10){
    margin-left: 14px;
    background: rgba(81, 203, 205, .1);
    color: #51CBCD;
  }
  .hangye_wrap>span:nth-child(11){
    background: rgba(84, 189, 113, .1);
    color: #54BD71;
  }
  .hangye_wrap>span:nth-child(12){
    background: rgba(202, 162, 85, .1);
    color: #CAA255;
  }
  .hangye_wrap>span:nth-child(13){
    background: rgba(100, 137, 198, .1);
    color: #6489C6;
  }
  .hangye_wrap>span:nth-child(14){
    background: rgba(144, 123, 198, .1);
    color: #907BC6;
  }
  .hangye_wrap>span:nth-child(15){
    background: rgba(233, 135, 81, .1);
    color: #E98751;
  }
  .hangye_wrap>span:nth-child(16){
    background: rgba(59, 188, 147, .1);
    color: #3BBC93;
  }
  .hangye_wrap>span:nth-child(17){
    background: rgba(149, 164, 79, .1);
    color: #95A44F;
  }
</style>
